import React from 'react'
import PhilosophyCard from '../components/PhilosophyCard'

const Ps = () => {
  return (
    <div>
      <PhilosophyCard></PhilosophyCard>
    </div>
  )
}

export default Ps
